/* eslint-disable max-len */

import axios from "axios";
import authHeader from "./auth-header";
import authAdminHeader from "./auth-admin-header";
import { toast } from "react-toastify";
export const API_URL = "https://app-dev.custodiy.com/api/";
// export const API_URL = "https://custodiy.com/api/";
const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const logout_user = async (data) => {
  return axiosInstance
    .post(`${API_URL}logout_user`, data)
    .then((response) => {
      // Handle the response as needed
      return response.data;
    })
    .catch((error) => {
      // Handle the error as needed
      console.error("Logout error:", error);
      throw error;
    });
};
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.Message) {
      const errorMessage = error.response.data.Message;
      if (errorMessage === "Token expired") {
        const user = JSON.parse(localStorage.getItem("user"));
        logout_user({ user_id: user?.data?.user.user_id });
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);
const user = JSON.parse(localStorage.getItem("user"));
export const login1 = (formData) =>
  axiosInstance.post(`${API_URL}login`, formData, { headers: authHeader() });
export const blockUser = (formData) =>
  axiosInstance.post(`${API_URL}blockUser`, formData, {
    headers: authHeader(),
  });
export const unblockUser = (formData) =>
  axiosInstance.post(`${API_URL}unblockUser`, formData, {
    headers: authHeader(),
  });
export const transferOwnership = (formData) =>
  axiosInstance.post(`${API_URL}transferOwnership`, formData, {
    headers: authHeader(),
  });
export const getCurrentOwner = (formData) =>
  axiosInstance.post(`${API_URL}getCurrentOwner`, formData, {
    headers: authHeader(),
  });
export const verifyTwoFactor1 = (formData) =>
  axiosInstance.post(`${API_URL}varify_2fa_otp`, formData, {
    headers: authHeader(),
  });
export const sendOtpGeneral = (formData) =>
  axiosInstance.post(`${API_URL}sendOtpGeneral`, formData, {
    headers: authHeader(),
  });
export const resendOtpGeneral = (formData) =>
  axiosInstance.post(`${API_URL}resendOtpGeneral`, formData, {
    headers: authHeader(),
  });
export const varifyOtpGeneral = (formData) =>
  axiosInstance.post(`${API_URL}varifyOtpGeneral`, formData, {
    headers: authHeader(),
  });
export const changePassword = (formData) =>
  axiosInstance.post(`${API_URL}changePassword`, formData, {
    headers: authHeader(),
  });
export const getTotalBalance = () =>
  axiosInstance.get(`${API_URL}total_usd_balance`, { headers: authHeader() });
export const getUserTotalBalance = () =>
  axiosInstance.get(`${API_URL}total_usd_balance`, { headers: authHeader() });
export const getSendAmount = () =>
  axiosInstance.get(`${API_URL}sentAmount`, { headers: authHeader() });
export const getReceiveAmount = () =>
  axiosInstance.get(`${API_URL}receivedAmount`, { headers: authHeader() });
export const getContractAmount = () =>
  axiosInstance.get(`${API_URL}contractAmount`, { headers: authHeader() });
export const getContracts = () =>
  axiosInstance.post(
    `${API_URL}getContracts`,
    { email: user.data.user.email, limit: 200, skip: 0 },
    { headers: authHeader() }
  );
// eslint-disable-next-line max-len
export const getSafebox = () =>
  axiosInstance.post(
    `${API_URL}getSafebox`,
    { limit: 100, skip: 0 },
    { headers: authHeader() }
  );
export const getApprovers = () =>
  axiosInstance.get(`${API_URL}searchApprover`, { headers: authHeader() });
export const getApprover = () =>
  axiosInstance.get(`${API_URL}users`, { headers: authHeader() });
export const addSafebox = () =>
  axiosInstance.post(`${API_URL}safebox`, "", { headers: authHeader() });
export const getBeneficiaryName = (name) =>
  axiosInstance.post(`${API_URL}search_name`, name, { headers: authHeader() });
export const CreateExternalOrder = (formData) =>
  axiosInstance.post(`${API_URL}CreateOrderExternal`, formData, {
    headers: authHeader(),
  });
export const ConfirmExternalOrder = (formData) =>
  axiosInstance.post(`${API_URL}updateOrderExternal`, formData, {
    headers: authHeader(),
  });
export const pausePlaySelling = (formData) =>
  axiosInstance.post(`${API_URL}pausePlaySelling`, formData, {
    headers: authHeader(),
  });
export const deleteMyProduct = (formData) =>
  axiosInstance.post(`${API_URL}deleteMyProduct`, formData, {
    headers: authHeader(),
  });
export const deleteProjectImagesData = (formData) =>
  axiosInstance.post(`${API_URL}deleteProjectImagesData`, formData, {
    headers: authHeader(),
  });
export const getProjectImagesDetailById = (formData) =>
  axiosInstance.post(`${API_URL}getProjectImagesDetailById`, formData, {
    headers: authHeader(),
  });
export const addOtcMarketPlace_package_general = (formData) =>
  axiosInstance.post(`${API_URL}addOtcMarketPlace_package_general`, formData, {
    headers: authHeader(),
  });
export const deleteOtcMarketPlacePackage = (formData) =>
  axiosInstance.post(`${API_URL}deleteOtcMarketPlacePackage`, formData, {
    headers: authHeader(),
  });
export const editOtcMarketPlacePackageGeneral = (formData) =>
  axiosInstance.post(`${API_URL}editOtcMarketPlacePackageGeneral`, formData, {
    headers: authHeader(),
  });

export const externalBSCCoins = (wallet_address) =>
  axiosInstance.post(
    `${API_URL}userBalanceExternalWalletBSCList`,
    { wallet_address },
    { headers: authHeader() }
  );
export const getUsdFee = () =>
  axiosInstance.get(`${API_URL}USDC_price`, { headers: authHeader() });
export const getUsdtFee = () =>
  axiosInstance.get(`${API_URL}USDT_price`, { headers: authHeader() });
export const getUsdtErcFee = () =>
  axiosInstance.get(`${API_URL}USDTERC20_price`, { headers: authHeader() });
export const getEthFee = () =>
  axiosInstance.get(`${API_URL}eth_price`, { headers: authHeader() });
export const getEurFee = () =>
  axiosInstance.get(`${API_URL}euroc_price`, { headers: authHeader() });
export const getGbpFee = () =>
  axiosInstance.get(`${API_URL}gbpt_price`, { headers: authHeader() });
export const getJypFee = () =>
  axiosInstance.get(`${API_URL}jpyc_price`, { headers: authHeader() });
export const getMatFee = () =>
  axiosInstance.get(`${API_URL}matic_price`, { headers: authHeader() });
export const getCtyFee = () =>
  axiosInstance.get(`${API_URL}cty_price`, { headers: authHeader() });
export const getPaxFee = () =>
  axiosInstance.get(`${API_URL}PAX_price`, { headers: authHeader() });
export const getBusdFee = () =>
  axiosInstance.get(`${API_URL}BUSD_price`, { headers: authHeader() });
export const getBnbFee = () =>
  axiosInstance.get(`${API_URL}bnb_price`, { headers: authHeader() });
export const getBTCFee = () =>
  axiosInstance.get(`${API_URL}BTC_price`, { headers: authHeader() });
export const getOPYFee = () =>
  axiosInstance.get(`${API_URL}OPY_price`, { headers: authHeader() });
export const getTokenPrice = (data) =>
  axiosInstance.post(`${API_URL}getTokenPrice`, data, {
    headers: authHeader(),
  });
export const getFTYFee = () =>
  axiosInstance.get(`${API_URL}FTY_price`, { headers: authHeader() });
export const getMETAFee = () =>
  axiosInstance.get(`${API_URL}META_price`, { headers: authHeader() });
export const getAdminWallet = () =>
  axiosInstance.get(`${API_URL}getAdminWallet`, { headers: authHeader() });
export const create = () =>
  axiosInstance.get(`${API_URL}create`, { headers: authHeader() });
export const tfaEnable = () =>
  axiosInstance.get(`${API_URL}TFAEnable`, { headers: authHeader() });
export const tfaDisable = () =>
  axiosInstance.get(`${API_URL}TFADisable`, { headers: authHeader() });
export const verifyTFA = (formData) =>
  axiosInstance.post(`${API_URL}verifyTFA`, formData, {
    headers: authHeader(),
  });
export const verifyTFAEmail = (formData) =>
  axiosInstance.post(`${API_URL}verifyTFA`, formData, {
    headers: authHeader(),
  });
// export const verifyTFAEmail = (formData) =>
//   axiosInstance.post(`${API_URL}verifyTFAEmail`, formData, {
//     headers: authHeader(),
//   });
export const getSafeboxDetail = (id) =>
  axiosInstance.get(`${API_URL}safebox/${id}`, { headers: authHeader() });
export const getContractDetail = (id) =>
  axiosInstance.get(`${API_URL}getTransactionDetail/${id}`, {
    headers: authHeader(),
  });
export const SendTwoFactor = () =>
  axiosInstance.post(`${API_URL}change_2fa_status`, "", {
    headers: authHeader(),
  });
export const getMyOtcBuyHistory = (data) =>
  axiosInstance.post(`${API_URL}getMyOtcBuyHistory`, data, {
    headers: authHeader(),
  });
export const approveContractOtp = () =>
  axiosInstance.post(`${API_URL}approve-contract-otp`, "", {
    headers: authHeader(),
  });
export const getDepositList = (data) =>
  axiosInstance.post(`${API_URL}getDepositList`, data, {
    headers: authHeader(),
  });
export const approveExternalWalletOtp = (otp) =>
  axiosInstance.post(
    `${API_URL}verifyOtpExternalWallet`,
    { otp },
    { headers: authHeader() }
  );
export const approveSenExternalWalletOtp = (otp) =>
  axiosInstance.post(
    `${API_URL}sendTokenExternalGasCalculation`,
    { otp },
    { headers: authHeader() }
  );
export const getUserInfo = () =>
  axiosInstance.get(`${API_URL}user_info`, { headers: authHeader() });
export const getUserTopFiveStrigaBalance = () =>
  axiosInstance.get(`${API_URL}getUserTopThreeStrigaBalance`, {
    headers: authHeader(),
  });
export const getUserTopFiveOnchainBalance = () =>
  axiosInstance.get(`${API_URL}getUserTopFiveOnchainBalance`, {
    headers: authHeader(),
  });
export const getUserTopFiveOTCBalance = () =>
  axiosInstance.get(`${API_URL}getUserTopFiveOTCBalance`, {
    headers: authHeader(),
  });
export const getUserTopFiveBalance = () =>
  axiosInstance.get(`${API_URL}getUserTopFiveBalance`, {
    headers: authHeader(),
  });
export const getUserInfo1 = (formData) =>
  axiosInstance.post(`${API_URL}user_infoEmail`, formData, {
    headers: authHeader(),
  });
export const getBecomeApprover = (formData) =>
  axiosInstance.post(`${API_URL}becomeApprover`, formData, {
    headers: authHeader(),
  });
export const becomeApprover = (formData) =>
  axiosInstance.post(`${API_URL}becomeApprover`, formData, {
    headers: authHeader(),
  });
export const sendOtpDynamic = (formData) =>
  axiosInstance.post(`${API_URL}sendOtpDynamic`, formData, {
    headers: authHeader(),
  });
export const addTelegramAccount = (formData) =>
  axiosInstance.post(`${API_URL}addTelegramAccount`, formData, {
    headers: authHeader(),
  });
export const buyOtcMarketPlacePackageBtc = (formData) =>
  axiosInstance.post(`${API_URL}buyOtcMarketPlacePackageBtc`, formData, {
    headers: authHeader(),
  });
export const buyOtcMarketPlacePackage_btc_general = (formData) =>
  axiosInstance.post(
    `${API_URL}buyOtcMarketPlacePackage_btc_general`,
    formData,
    {
      headers: authHeader(),
    }
  );
export const safeboxApprove = (id) =>
  axiosInstance.post(
    `${API_URL}safebox-approve`,
    { id },
    { headers: authHeader() }
  );
export const safeboxReject = (id) =>
  axiosInstance.post(
    `${API_URL}safebox-reject`,
    { id },
    { headers: authHeader() }
  );
export const calculateAdminCommissions = (commissionVals) =>
  axiosInstance.post(`${API_URL}calculateAdminCommissions`, commissionVals, {
    headers: authHeader(),
  });
export const getIpfs = (ipfs) =>
  axiosInstance.post(`${API_URL}uploadFileIpfs`, ipfs, {
    headers: authHeader(),
  });

// Market place routes
export const createMarketPlaceInternal = (formData) =>
  axiosInstance.post(`${API_URL}createMarketPlaceInternal`, formData, {
    headers: authHeader(),
  });
export const createMarketPlaceExternal = (formData) =>
  axiosInstance.post(`${API_URL}createMarketPlaceExternal`, formData, {
    headers: authHeader(),
  });
export const createProduct = (formData) =>
  axiosInstance.post(`${API_URL}uploadProduct`, formData, {
    headers: authHeader(),
  });
export const updateProduct = (formData) =>
  axiosInstance.post(`${API_URL}updateProduct`, formData, {
    headers: authHeader(),
  });
export const CreateOrder = (formData) =>
  axiosInstance.post(`${API_URL}CreateOrder`, formData, {
    headers: authHeader(),
  });
export const CreateOrderInternal = (formData) =>
  axiosInstance.post(`${API_URL}CreateOrderInternal`, formData, {
    headers: authHeader(),
  });
export const getService = () =>
  axiosInstance.get(`${API_URL}getService`, {
    headers: authHeader(),
  });
export const CreateOrderStriga = (formData) =>
  axiosInstance.post(`${API_URL}CreateOrderStriga`, formData, {
    headers: authHeader(),
  });
export const transferStoreFee = (Data) =>
  axiosInstance.post(`${API_URL}transferStoreCreation_fee`, Data, {
    headers: authHeader(),
  });
  
export const createpaymentintent = (Data) =>
  axiosInstance.post(`${API_URL}create-payment-intent`, Data, {
    headers: authHeader(),
  });
export const buyOtcStripe_overview = (Data) =>
  axiosInstance.post(`${API_URL}buyOtcStripeOverview`, Data, {
    headers: authHeader(),
  });
export const buyOtcMarketPlacPpackageStripe = (Data) =>
  axiosInstance.post(`${API_URL}buyOtcMarketPlacPpackageStripe`, Data, {
    headers: authHeader(),
  });

export const checkStore = () =>
  axiosInstance.get(`${API_URL}checkIsStoreExists`, { headers: authHeader() });
export const getCategory = () =>
  axiosInstance.get(`${API_URL}getCategories`, { headers: authHeader() });
export const getMyStore = () =>
  axiosInstance.get(`${API_URL}getMy_store`, { headers: authHeader() });
export const getHotProducts = () =>
  axiosInstance.get(`${API_URL}getHotProducts`, { headers: authHeader() });
export const getWalletActivities = () =>
  axiosInstance.get(`${API_URL}walletActivities`, { headers: authHeader() });
export const getChatMessages = (data) =>
  axiosInstance.post(`${API_URL}getChatMessages`, data, {
    headers: authHeader(),
  });
export const addCertificate = (data) =>
  axiosInstance.post(`${API_URL}addCertificate`, data, {
    headers: authHeader(),
  });
export const submitOtcMarketplaceRequest = (data) =>
  axiosInstance.post(`${API_URL}submitOtcMarketplaceRequest`, data, {
    headers: authHeader(),
  });
export const walletSendReceiveActivities = (data) =>
  axiosInstance.post(`${API_URL}walletSendReceiveActivities`, data, {
    headers: authHeader(),
  });
export const flashHotProducts = () =>
  axiosInstance.get(`${API_URL}getFlashSale`, { headers: authHeader() });
export const getMyProduct = (formData) =>
  axiosInstance.post(`${API_URL}getMy_products`, formData, {
    headers: authHeader(),
  });
export const getProjectImagesData = (formData) =>
  axiosInstance.post(`${API_URL}getProjectImagesData`, formData, {
    headers: authHeader(),
  });
export const getFee = (formData) =>
  axiosInstance.post(`${API_URL}getWithdrawFee`, formData, {
    headers: authHeader(),
  });
export const getProductData = (productId) =>
  axiosInstance.post(`${API_URL}getProductInformation`, productId, {
    headers: authHeader(),
  });
export const getProductReview = (productId) =>
  axiosInstance.post(`${API_URL}getProductReviews`, productId, {
    headers: authHeader(),
  });
export const getSingleProductDetail = (name) =>
  axiosInstance.post(`${API_URL}searchProduct`, name, {
    headers: authHeader(),
  });
export const getStoreInfoById = (name) =>
  axiosInstance.post(`${API_URL}getStoreInfoById`, name, {
    headers: authHeader(),
  });
export const getAllStore = (data) =>
  axiosInstance.post(`${API_URL}getAllStore`, data, { headers: authHeader() });
export const searchAllStore = (data) =>
  axiosInstance.post(`${API_URL}searchProductByNameOrSubCategory`, data, {
    headers: authHeader(),
  });
export const editMyStore = (data) =>
  axiosInstance.post(`${API_URL}editMyStoreDetails`, data, {
    headers: authHeader(),
  });
export const editStoreLogo = (data) =>
  axiosInstance.post(`${API_URL}changeStoreLogo`, data, {
    headers: authHeader(),
  });
export const getMyOrders = (data) =>
  axiosInstance.post(`${API_URL}getMyOrders`, data, { headers: authHeader() });
export const getMarketplaceContracts = (data) =>
  axiosInstance.post(`${API_URL}getMyMarketPlaceContracts`, data, {
    headers: authHeader(),
  });
export const delProductImg = (data) =>
  axiosInstance.post(`${API_URL}deleteProductImage`, data, {
    headers: authHeader(),
  });
export const deleteProductDoc = (data) =>
  axiosInstance.post(`${API_URL}deleteProductDocs`, data, {
    headers: authHeader(),
  });
export const getMyApproval = (data) =>
  axiosInstance.post(`${API_URL}getOrderForMyApproval`, data, {
    headers: authHeader(),
  });
export const rejectOrder = (data) =>
  axiosInstance.post(`${API_URL}rejectOrder`, data, { headers: authHeader() });
export const rejectOrderBank = (data) =>
  axiosInstance.post(`${API_URL}rejectOrderBank`, data, {
    headers: authHeader(),
  });
export const buyerApproveOrder = (data) =>
  axiosInstance.post(`${API_URL}buyerApproveOrder`, data, {
    headers: authHeader(),
  });
export const buyerApproveOrderBank = (data) =>
  axiosInstance.post(`${API_URL}buyerApproveOrderBank`, data, {
    headers: authHeader(),
  });
export const buyerRejectOrder = (data) =>
  axiosInstance.post(`${API_URL}buyerRejectOrder`, data, {
    headers: authHeader(),
  });
export const buyeRejectOrderBank = (data) =>
  axiosInstance.post(`${API_URL}buyeRejectOrderBank`, data, {
    headers: authHeader(),
  });
export const approveOrder = (data) =>
  axiosInstance.post(`${API_URL}approveOrder`, data, { headers: authHeader() });
export const approveOrderBank = (data) =>
  axiosInstance.post(`${API_URL}approveOrderBank`, data, {
    headers: authHeader(),
  });

export const getMyOrdersAsBuyer = (data) =>
  axiosInstance.post(`${API_URL}getMyOrdersAsBuyer`, data, {
    headers: authHeader(),
  });
export const getCustomOffers = (data) =>
  axiosInstance.post(`${API_URL}getMyCustomOffer`, data, {
    headers: authHeader(),
  });
export const submitRatting = (data) =>
  axiosInstance.post(`${API_URL}submitRatting`, data, {
    headers: authHeader(),
  });
export const uploadInvoice = (data) =>
  axiosInstance.post(`${API_URL}uploadInvoice`, data, {
    headers: authHeader(),
  });
export const updateOrderStatusSeller = (data) =>
  axiosInstance.post(`${API_URL}updateOrderStatusSeller`, data, {
    headers: authHeader(),
  });
export const getGlobalProducts = (data) =>
  axiosInstance.post(`${API_URL}getGlobalProducts`, data, {
    headers: authHeader(),
  });
export const getRecentHistory = (data) =>
  axiosInstance.post(`${API_URL}getMyRecentSearch`, data, {
    headers: authHeader(),
  });
export const getSameProduct = (data) =>
  axiosInstance.post(`${API_URL}getSameProducts`, data, {
    headers: authHeader(),
  });
export const createTicket = (data) =>
  axiosInstance.post(`${API_URL}createTicket`, data, { headers: authHeader() });
export const getChat = (data) =>
  axiosInstance.post(`${API_URL}getchat`, data, { headers: authHeader() });
export const addVote = (data) =>
  axiosInstance.post(`${API_URL}addVote`, data, { headers: authHeader() });
export const add = (data) =>
  axiosInstance.post(`${API_URL}add`, data, { headers: authHeader() });

export const getTickets = (data) =>
  axiosInstance.post(`${API_URL}getTickets`, data, { headers: authHeader() });
export const adminSwitchProfile = (data) =>
  axiosInstance.post(`${API_URL}adminSwitchProfile`, data, {
    headers: authAdminHeader(),
  });

export const getTicketMessages = (data) =>
  axiosInstance.post(`${API_URL}getTicketMessages`, data, {
    headers: authHeader(),
  });
export const ticketReply = (data) =>
  axiosInstance.post(`${API_URL}ticketReply`, data, { headers: authHeader() });
export const updateTicketStatus = (data) =>
  axiosInstance.post(`${API_URL}updateTicketStatus`, data, {
    headers: authHeader(),
  });
export const searchTicket = (data) =>
  axiosInstance.post(`${API_URL}searchTicket`, data, { headers: authHeader() });

export const getNotification = (data) =>
  axiosInstance.post(`${API_URL}getNotification`, data, {
    headers: authHeader(),
  });
export const deleteSurvey = (data) =>
  axiosInstance.post(`${API_URL}deleteSurvey`, data, {
    headers: authHeader(),
  });
export const submitVote = (data) =>
  axiosInstance.post(`${API_URL}submitVote`, data, {
    headers: authHeader(),
  });
export const updateStatusElection = (data) =>
  axiosInstance.post(`${API_URL}updateStatusElection`, data, {
    headers: authHeader(),
  });
export const getMyVote = (data) =>
  axiosInstance.post(`${API_URL}getMyVote`, data, { headers: authHeader() });
export const getMyVoteStatus = (data) =>
  axiosInstance.post(`${API_URL}getMyVoteStatus`, data, {
    headers: authHeader(),
  });
export const getVoteOptionCount = (data) =>
  axiosInstance.post(`${API_URL}getVoteOptionCount`, data, {
    headers: authHeader(),
  });
export const getTotalVotes = (data) =>
  axiosInstance.post(`${API_URL}getTotalVotes`, data, {
    headers: authHeader(),
  });

export const markAllAsRead = (data) =>
  axiosInstance.post(`${API_URL}markAllAsRead`, data, {
    headers: authHeader(),
  });

// Market Coins
export const getBTC = () =>
  axiosInstance.get(`${API_URL}convertToUSD/BTC`, { headers: authHeader() });
export const getETH = () =>
  axiosInstance.get(`${API_URL}convertToUSD/ETH`, { headers: authHeader() });
export const getBNB = () =>
  axiosInstance.get(`${API_URL}convertToUSD/BNB`, { headers: authHeader() });
export const getUSDT = () =>
  axiosInstance.get(`${API_URL}convertToUSD/USDT`, { headers: authHeader() });
export const getUSDC = () =>
  axiosInstance.get(`${API_URL}convertToUSD/USDC`, { headers: authHeader() });
export const getPAX = () =>
  axiosInstance.get(`${API_URL}convertToUSD/PAX`, { headers: authHeader() });
export const getBUSD = () =>
  axiosInstance.get(`${API_URL}convertToUSD/BUSD`, { headers: authHeader() });
export const getGBPT = () =>
  axiosInstance.get(`${API_URL}convertToUSD/GBPT`, { headers: authHeader() });
export const getJPYC = () =>
  axiosInstance.get(`${API_URL}convertToUSD/JPYC`, { headers: authHeader() });
export const getEUROC = () =>
  axiosInstance.get(`${API_URL}convertToUSD/EUROC`, { headers: authHeader() });
export const getTokenDetailsWithPrice = () =>
  axiosInstance.get(`${API_URL}getTokenDetailsWithPrice`, {
    headers: authHeader(),
  });
export const getMATIC = () =>
  axiosInstance.get(`${API_URL}convertToUSD/MATIC`, { headers: authHeader() });
export const getCTY = () =>
  axiosInstance.get(`${API_URL}convertToUSD/CTY`, { headers: authHeader() });
export const getMETA = () =>
  axiosInstance.get(`${API_URL}convertToUSD/META`, { headers: authHeader() });
export const getFTY = () =>
  axiosInstance.get(`${API_URL}convertToUSD/FTY`, { headers: authHeader() });
export const getOPY = () =>
  axiosInstance.get(`${API_URL}convertToUSD/OPY`, { headers: authHeader() });

export const gettotalBalance = () =>
  axiosInstance.get(`${API_URL}public/totalBalance`, { headers: authHeader() });
  
export const getRecentProduct = () =>
  axiosInstance.get(`${API_URL}public/getRecentProduct`, { headers: authHeader() });


export const getcontractStats = () =>
  axiosInstance.get(`${API_URL}public/contractStats`, {
    headers: authHeader(),
  });
export const getsafeBoxStats = () =>
  axiosInstance.get(`${API_URL}public/safeBoxStats`, { headers: authHeader() });
export const gettotalAccounts = () =>
  axiosInstance.get(`${API_URL}public/totalAccounts`, {
    headers: authHeader(),
  });
export const getWeekGraph = () =>
  axiosInstance.get(`${API_URL}getWeekGraph`, { headers: authHeader() });
export const getCalendarActivities = () =>
  axiosInstance.get(`${API_URL}getCalendarActivity`, { headers: authHeader() });
export const getTransactionReport = (data) =>
  axiosInstance.post(`${API_URL}getTransactionReport`, data, {
    headers: authHeader(),
  });
export const createCalendarActivity = (data) =>
  axiosInstance.post(`${API_URL}createNewActivity`, data, {
    headers: authHeader(),
  });
export const getChartPricing = (data) =>
  axiosInstance.post(`${API_URL}getPriceChatData`, data, {
    headers: authHeader(),
  });
export const updateCalenderActivity = (data) =>
  axiosInstance.post(`${API_URL}updateCalenderActivity`, data, {
    headers: authHeader(),
  });

export const getPublicTransactions = () =>
  axiosInstance.get(`${API_URL}public/transactions`, { headers: authHeader() });
export const getdetailSearch = (id) =>
  axiosInstance.get(`${API_URL}public/getTransactionDetails/${id}`, {
    headers: authHeader(),
  });
export const getUSDTConversion = (data) =>
  axiosInstance.post(`${API_URL}USDT`, data, { headers: authHeader() });
export const getUSDCConversion = (data) =>
  axiosInstance.post(`${API_URL}USDC`, data, { headers: authHeader() });
export const getPAXConversion = (data) =>
  axiosInstance.post(`${API_URL}PAX`, data, { headers: authHeader() });
export const getBUSDConversion = (data) =>
  axiosInstance.post(`${API_URL}BUSD`, data, { headers: authHeader() });
export const getCTYConversion = (data) =>
  axiosInstance.post(`${API_URL}CTY`, data, { headers: authHeader() });
export const getETHConversion = (data) =>
  axiosInstance.post(`${API_URL}ETH`, data, { headers: authHeader() });
export const getBNBConversion = (data) =>
  axiosInstance.post(`${API_URL}BNB`, data, { headers: authHeader() });
export const getEUROCConversion = (data) =>
  axiosInstance.post(`${API_URL}EUROC`, data, { headers: authHeader() });
export const getJPYCConversion = (data) =>
  axiosInstance.post(`${API_URL}JPYC`, data, { headers: authHeader() });
export const getGBPTConversion = (data) =>
  axiosInstance.post(`${API_URL}GBPT`, data, { headers: authHeader() });
export const getMATICConversion = (data) =>
  axiosInstance.post(`${API_URL}MATIC`, data, { headers: authHeader() });
export const getUserBalanceExternalWallet = (data) =>
  axiosInstance.post(`${API_URL}userBalanceExternalWallet`, data, {
    headers: authHeader(),
  });
export const blockOrUnblockCard = (data) =>
  axiosInstance.post(`${API_URL}blockOrUnblockCard`, data, {
    headers: authHeader(),
  });
export const createCard = (data) =>
  axiosInstance.post(`${API_URL}createCard`, data, {
    headers: authHeader(),
  });
export const updateCardPassword = (data) =>
  axiosInstance.post(`${API_URL}updateCardPassword`, data, {
    headers: authHeader(),
  });
export const getUserBalanceInternalWallet = () =>
  axiosInstance.get(`${API_URL}userBalanceInternalWallet`, {
    headers: authHeader(),
  });
export const userBalanceInternalWalletSubWallet = () =>
  axiosInstance.get(`${API_URL}userBalanceInternalWalletSubWallet`, {
    headers: authHeader(),
  });
export const createSubWallet = () =>
  axiosInstance.get(`${API_URL}createSubWallet`, {
    headers: authHeader(),
  });
export const myrequestStatus = () =>
  axiosInstance.get(`${API_URL}myrequestStatus`, {
    headers: authHeader(),
  });
export const userInternalWalletBalanceRefresh = async () =>
  await axiosInstance.get(`${API_URL}upcomingBalanceRefresh`, {
    headers: authHeader(),
  });
export const getProject = async () =>
  await axiosInstance.get(`${API_URL}getProject`, {
    headers: authHeader(),
  });

export const getOPYConversion = (data) =>
  axiosInstance.post(`${API_URL}OPY`, data, { headers: authHeader() });
export const createProject = (data) =>
  axiosInstance.post(`${API_URL}createProject`, data, {
    headers: authHeader(),
  });
export const getFTYConversion = (data) =>
  axiosInstance.post(`${API_URL}FTY`, data, { headers: authHeader() });
export const searchStoretByNameOrCategory = (data) =>
  axiosInstance.post(`${API_URL}searchStoretByNameOrCategory`, data, {
    headers: authHeader(),
  });
export const getUserBalanceExternalWalletBSCList = (data) =>
  axiosInstance.post(`${API_URL}userBalanceExternalWalletBSCList`, data, {
    headers: authHeader(),
  });
export const uploadBeneficiary_docs = (data) =>
  axiosInstance.post(`${API_URL}uploadBeneficiary_docs`, data, {
    headers: authHeader(),
  });
export const acceptCustomOffer = (data) =>
  axiosInstance.post(`${API_URL}acceptCustomOffer`, data, {
    headers: authHeader(),
  });
export const rejectCustomOffer = (data) =>
  axiosInstance.post(`${API_URL}rejectCustomOffer`, data, {
    headers: authHeader(),
  });
export const verifyEmailAddress = (data) =>
  axiosInstance.post(`${API_URL}verifyEmailAddress`, data, {
    headers: authHeader(),
  });
export const verifyMobile = (data) =>
  axiosInstance.post(`${API_URL}verifyMobile`, data, {
    headers: authHeader(),
  });
export const verifyKYC = (data) =>
  axiosInstance.post(`${API_URL}verifyKYC`, data, {
    headers: authHeader(),
  });
export const getAllWallets = (data) =>
  axiosInstance.post(`${API_URL}getAllWallets`, data, {
    headers: authHeader(),
  });
export const sendCustomOffer = (data) =>
  axiosInstance.post(`${API_URL}sendCustomOffer`, data, {
    headers: authHeader(),
  });
export const getAllOtcMarketPlaceWebapp = (data) =>
  axiosInstance.post(`${API_URL}getAllOtcMarketPlaceWebapp`, data, {
    headers: authHeader(),
  });
export const getOtcMarketPlacePackageWebApp = (data) =>
  axiosInstance.post(`${API_URL}getOtcMarketPlacePackageWebApp`, data, {
    headers: authHeader(),
  });
export const getMarketPlaceDetailsById = (formData) =>
  axiosInstance.post(`${API_URL}getMarketPlaceDetailsById`, formData, {
    headers: authHeader(),
  });
export const getMarketPlaceStatus = () =>
  axiosInstance.get(`${API_URL}getMarketPlaceStatus`, {
    headers: authHeader(),
  });
export const getUpdatedContract = () =>
  axiosInstance.get(`${API_URL}getUpdatedContract`, {
    headers: authHeader(),
  });
export const sendAccessRequest = (data) =>
  axiosInstance.post(`${API_URL}sendAccessRequest`, data, {
    headers: authHeader(),
  });
export const buyOtcMarketPlacePackage = (data) =>
  axiosInstance.post(`${API_URL}buyOtcMarketPlacePackage`, data, {
    headers: authHeader(),
  });
export const buyOtcMarketPlacePackage_general = (data) =>
  axiosInstance.post(`${API_URL}buyOtcMarketPlacePackage_general`, data, {
    headers: authHeader(),
  });
export const buyOtcMarketPlacePackageSol = (data) =>
  axiosInstance.post(`${API_URL}buyOtcMarketPlacePackageSol`, data, {
    headers: authHeader(),
  });
export const getMyOtcPackageTrx = (data) =>
  axiosInstance.post(`${API_URL}getMyOtcPackageTrx`, data, {
    headers: authHeader(),
  });
export const getOtcPackageTrx = (data) =>
  axiosInstance.post(`${API_URL}getOtcPackageTrx`, data, {
    headers: authHeader(),
  });
export const transferBalance = (data) =>
  axiosInstance.post(`${API_URL}transferBalance`, data, {
    headers: authHeader(),
  });
export const transferBalance_sol = (data) =>
  axiosInstance.post(`${API_URL}transferBalance_sol`, data, {
    headers: authHeader(),
  });
export const getPoolBalance = (data) =>
  axiosInstance.post(`${API_URL}getPoolBalance`, data, {
    headers: authHeader(),
  });
export const buyProjectOtcpackage = (data) =>
  axiosInstance.post(`${API_URL}buyProjectOtcpackage`, data, {
    headers: authHeader(),
  });
export const poolHolderGraph = (data) =>
  axiosInstance.post(`${API_URL}poolHolderGraph`, data, {
    headers: authHeader(),
  });
export const getAccessRequest = (data) =>
  axiosInstance.post(`${API_URL}getAccessRequest`, data, {
    headers: authHeader(),
  });
export const getAccessRequestProject = (data) =>
  axiosInstance.post(`${API_URL}getAccessRequestProject`, data, {
    headers: authHeader(),
  });
export const checkMyAccess = (data) =>
  axiosInstance.post(`${API_URL}checkMyAccess`, data, {
    headers: authHeader(),
  });
export const getAllProjectOtc = (data) =>
  axiosInstance.post(`${API_URL}getAllProjectOtc`, data, {
    headers: authHeader(),
  });
export const createProjectOtc = (data) =>
  axiosInstance.post(`${API_URL}createProjectOtc`, data, {
    headers: authHeader(),
  });
export const updateProjectOtc = (data) =>
  axiosInstance.post(`${API_URL}updateProjectOtc`, data, {
    headers: authHeader(),
  });
export const addProjectOtcPackage = (data) =>
  axiosInstance.post(`${API_URL}addProjectOtcPackage`, data, {
    headers: authHeader(),
  });
export const editProjectOtcPackage = (data) =>
  axiosInstance.post(`${API_URL}editProjectOtcPackage`, data, {
    headers: authHeader(),
  });
export const getProjectOtcPoolBalance = (data) =>
  axiosInstance.post(`${API_URL}getProjectOtcPoolBalance`, data, {
    headers: authHeader(),
  });
export const getAllOtcPackages = (data) =>
  axiosInstance.post(`${API_URL}getAllOtcPackages`, data, {
    headers: authHeader(),
  });
export const submitAccessRequest = (data) =>
  axiosInstance.post(`${API_URL}submitAccessRequest`, data, {
    headers: authHeader(),
  });
export const updateAccessRequestProject = (data) =>
  axiosInstance.post(`${API_URL}updateAccessRequestProject`, data, {
    headers: authHeader(),
  });
export const getToken = (data) =>
  axiosInstance.post(`${API_URL}getToken`, data, {
    headers: authHeader(),
  });
export const swapOverviewMarketPlacePackge = (data) =>
  axiosInstance.post(`${API_URL}swapOverviewMarketPlacePackge`, data, {
    headers: authHeader(),
  });
export const swapOverviewMarketPlacePackge_general = (data) =>
  axiosInstance.post(`${API_URL}swapOverviewMarketPlacePackge_general`, data, {
    headers: authHeader(),
  });
export const swapOtcMarketPlacePackge = (data) =>
  axiosInstance.post(`${API_URL}swapOtcMarketPlacePackge`, data, {
    headers: authHeader(),
  });
export const swapOtcMarketPlacePackgeSol = (data) =>
  axiosInstance.post(`${API_URL}swapOtcMarketPlacePackgeSol`, data, {
    headers: authHeader(),
  });
export const getUserPendingBalance = (data) =>
  axiosInstance.post(`${API_URL}getUserPendingBalance`, data, {
    headers: authHeader(),
  });
export const getMyFromSymbolBalance = (data) =>
  axiosInstance.post(`${API_URL}getMyFromSymbolBalance`, data, {
    headers: authHeader(),
  });

export const getUserPendingBalanceWebapp = () =>
  axiosInstance.get(`${API_URL}getUserPendingBalanceWebapp`, {
    headers: authHeader(),
  });
export const getMyRequest = () =>
  axiosInstance.get(`${API_URL}getMyRequest`, {
    headers: authHeader(),
  });
export const exchangeRatesStriga = () =>
  axiosInstance.get(`${API_URL}exchangeRatesStriga`, {
    headers: authHeader(),
  });
export const getMySubAccounts = (data) =>
  axiosInstance.post(`${API_URL}getMySubAccounts`, data, {
    headers: authHeader(),
  });
export const submitNewAccountRequest = (data) =>
  axiosInstance.post(`${API_URL}submitNewAccountRequest`, data, {
    headers: authHeader(),
  });
export const switchAccount = (data) =>
  axiosInstance.post(`${API_URL}switchAccount`, data, {
    headers: authHeader(),
  });
export const getotcMarketplaceDetails = () =>
  axiosInstance.get(`${API_URL}public/otcMarketplaceDetails`, {});
export const getstoreDetails = () =>
  axiosInstance.get(`${API_URL}public/storeDetails`, {});
export const getFees = () => axiosInstance.get(`${API_URL}public/getFee`, {});
export const getAllSolBalanceUser = (symbol) =>
  axiosInstance.get(`${API_URL}getAllSolBalanceUser?symbol=${symbol}`, {});
export const getAllTokens = (formData) =>
  axiosInstance.post(`${API_URL}getToken`, formData, {
    headers: authHeader(),
  });
export const getExternalStoreFee = (formData) =>
  axiosInstance.post(`${API_URL}getFee`, formData, {
    headers: authHeader(),
  });
export const getSubAccountType = (formData) =>
  axiosInstance.post(`${API_URL}getSubAccountType`, formData, {
    headers: authHeader(),
  });
export const getTotalFeeSubAccounts = (formData) =>
  axiosInstance.post(`${API_URL}getTotalFeeSubAccounts`, formData, {
    headers: authHeader(),
  });
export const getSymbolPrice = (formData) =>
  axiosInstance.post(`${API_URL}getSymbolPrice`, formData, {
    headers: authHeader(),
  });
export const uploadProfile = (formData) =>
  axiosInstance.post(`${API_URL}uploadProfile`, formData, {
    headers: authHeader(),
  });
export const getSwapOverview = (formData) =>
  axiosInstance.post(`${API_URL}getSwapOverview`, formData, {
    headers: authHeader(),
  });
export const swap = (formData) =>
  axiosInstance.post(`${API_URL}swap`, formData, {
    headers: authHeader(),
  });
export const sendSubWalletToken = (formData) =>
  axiosInstance.post(`${API_URL}sendSubWalletToken`, formData, {
    headers: authHeader(),
  });
export const sendSubWalletSolToken = (formData) =>
  axiosInstance.post(`${API_URL}sendSubWalletSolToken`, formData, {
    headers: authHeader(),
  });
export const getResultKYC = () =>
  axiosInstance.get(`${API_URL}getResultKYC`, { headers: authHeader() });

export const startKYC = (formData) =>
  axiosInstance.post(`${API_URL}startKYC`, formData, {
    headers: authHeader(),
  });
export const sendToken = (formData) =>
  axiosInstance.post(`${API_URL}sendToken`, formData, {
    headers: authHeader(),
  });
export const addTrackingDescription = (formData) =>
  axiosInstance.post(`${API_URL}addTrackingDescription`, formData, {
    headers: authHeader(),
  });
export const createUserOnStriga = (formData) =>
  axiosInstance.post(`${API_URL}createUserOnStriga`, formData, {
    headers: authHeader(),
  });
export const exchangeRates = (formData) =>
  axiosInstance.post(`${API_URL}exchangeRates`, formData, {
    headers: authHeader(),
  });
export const confirmOTP = (formData) =>
  axios.post(`${API_URL}confirmOTP`, formData, {
    headers: authHeader(),
  });
export const initiateSepaTransaction = (formData) =>
  axios.post(`${API_URL}initiateSepaTransaction`, formData, {
    headers: authHeader(),
  });
export const swapCurrencies = (formData) =>
  axiosInstance.post(`${API_URL}swapCurrencies`, formData, {
    headers: authHeader(),
  });
export const uploadProfileDocuments = (formData) =>
  axiosInstance.post(`${API_URL}uploadProfileDocuments`, formData, {
    headers: authHeader(),
  });
export const initiateIntraledgerTransaction = (formData) =>
  axios.post(`${API_URL}initiateIntraledgerTransaction`, formData, {
    headers: authHeader(),
  });
export const getFeeEstimateStriga = (formData) =>
  axios.post(`${API_URL}getFeeEstimateStriga`, formData, {
    headers: authHeader(),
  });
export const initiateOnChainTransaction = (formData) =>
  axios.post(`${API_URL}initiateOnChainTransaction`, formData, {
    headers: authHeader(),
  });
export const bankTransferFromVirtualWallet = (formData) =>
  axios.post(`${API_URL}bankTransferFromVirtualWallet`, formData, {
    headers: authHeader(),
  });
export const getProjectOwnerProducts = (formData) =>
  axios.post(`${API_URL}getProjectOwnerProducts`, formData, {
    headers: authHeader(),
  });
export const getProductInformation = (formData) =>
  axios.post(`${API_URL}getProductTrackingInfo`, formData, {
    headers: authHeader(),
  });
export const getTrackingInnerInfo = (formData) =>
  axios.post(`${API_URL}getTrackingInnerInfo`, formData, {
    headers: authHeader(),
  });
export const addProductTrackingDetail = (formData) =>
  axios.post(`${API_URL}addProductTrackingDetail`, formData, {
    headers: authHeader(),
  });
export const getPrivacyOrCookies = (formData) =>
  axios.post(`${API_URL}getPrivacyOrCookies`, formData, {
    headers: authHeader(),
  });

export const getStrigaWalletAddress = (formData) =>
  axios.post(`${API_URL}getStrigaWalletAddress`, formData, {
    headers: authHeader(),
  });
export const getLocation = () =>
  axiosInstance.get(`${API_URL}getLocation`, {
    headers: authHeader(),
  });
export const onChainWalletRefresh = () =>
  axiosInstance.get(`${API_URL}onChainWalletRefresh`, {
    headers: authHeader(),
  });
export const getUserLockBalanceWebapp = () =>
  axiosInstance.get(`${API_URL}getUserLockBalanceWebapp`, {
    headers: authHeader(),
  });
export const getNews = () => axiosInstance.get(`${API_URL}getNews`, {});
export const getVotingGeneral = () =>
  axiosInstance.get(`${API_URL}getVotingGeneral`, {});
export const getPartner = () => axiosInstance.get(`${API_URL}getPartner`, {});
export const getUser = () =>
  axios.get(`${API_URL}getUserStriga`, { headers: authHeader() });
export const getAccountHistory = () =>
  axios.get(`${API_URL}getAccountHistory`, { headers: authHeader() });
export const getAllCards = () =>
  axios.get(`${API_URL}getAllCards`, { headers: authHeader() });
export default {
  sendOtpGeneral,
  resendOtpGeneral,
  varifyOtpGeneral,
  deleteProductDoc,
  getFTYConversion,
  create,
  tfaEnable,
  tfaDisable,
  getOPYConversion,
  changePassword,
  getAdminWallet,
  verifyTFA,
  CreateExternalOrder,
  ConfirmExternalOrder,
  getFee,
  getChatMessages,
  getWalletActivities,
  walletSendReceiveActivities,
  getBTC,
  getETH,
  getBNB,
  getOPY,
  getUSDT,
  getUSDC,
  getPAX,
  getBUSD,
  getGBPT,
  getJPYC,
  getEUROC,
  getMATIC,
  getFTY,
  getCTY,
  getMETA,
  getOPYFee,
  getFTYFee,
  getTokenPrice,
  getFTYFee,

  gettotalBalance,
  getcontractStats,
  getsafeBoxStats,
  gettotalAccounts,
  getPublicTransactions,
  getdetailSearch,
  adminSwitchProfile,
  getTotalBalance,
  getNotification,
  markAllAsRead,
  getContractAmount,
  getReceiveAmount,
  getSendAmount,
  getContracts,
  getSafebox,
  getUsdFee,
  getUsdtFee,
  getEthFee,
  getEurFee,
  getGbpFee,
  getJypFee,
  getMatFee,
  getCtyFee,
  getPaxFee,
  getBusdFee,
  getBnbFee,
  getBTCFee,
  getMETAFee,
  getSafeboxDetail,
  SendTwoFactor,
  approveContractOtp,
  getUserInfo,
  safeboxApprove,
  safeboxReject,
  approveExternalWalletOtp,
  calculateAdminCommissions,
  approveSenExternalWalletOtp,
  getIpfs,
  createMarketPlaceInternal,
  createMarketPlaceExternal,
  getCategory,
  transferStoreFee,
  checkStore,
  getMyStore,
  createProduct,
  getMyProduct,
  getProductData,
  getAllStore,
  searchAllStore,
  editMyStore,
  editStoreLogo,
  getMyOrders,
  getMyApproval,
  approveOrder,
  rejectOrder,
  getMyOrdersAsBuyer,
  submitRatting,
  updateOrderStatusSeller,
  getUserTotalBalance,
  getGlobalProducts,
  getRecentHistory,
  createTicket,
  getTickets,
  getTicketMessages,
  ticketReply,
  updateTicketStatus,
  searchTicket,
  searchStoretByNameOrCategory,
  getUserBalanceExternalWallet,
  getUserBalanceInternalWallet,
  getUserBalanceExternalWalletBSCList,
  getCalendarActivities,
  createCalendarActivity,
  getChartPricing,
};
