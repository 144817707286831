/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Ring } from "@uiball/loaders";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

import { Button } from "@/shared/components/Button";

import {
  getMarketPlaceDetailsById,
  getOtcMarketPlacePackageWebApp,
  buyOtcMarketPlacePackageBtc,
  sendAccessRequest,
  getMyOtcPackageTrx,
  buyOtcMarketPlacePackage,
  getPoolBalance,
  buyOtcMarketPlacePackageSol,
  getMyFromSymbolBalance,
  buyOtcMarketPlacePackage_general,
  buyOtcMarketPlacePackage_btc_general,
  buyOtcMarketPlacPpackageStripe,
} from "../../../utils/services/user.service";
import { useLocation } from "react-router";

import PackageCard from "./MarketplacePackageCard";
import FeeComponent from "./FeeComponent";
import StatusComponent from "../StatusCompoent/StatusComponent";
import AllTransectionTable from "./AllTransectionTable";
import VirtualBalaceTable from "./VirtualBalaceTable";

import OtcGraphHolder from "./OtcHolderGraph";
import PoolInfoGraph from "./PoolInfoGraph.js/PoolInfoGraph";
import InfoItem from "./InfoItem";
import numeral from "numeral";
import { Link } from "react-router-dom";
import moment from "moment";
import CreateOtcGeneralPackage from "./CreateOtcGeneralPackage";
import GeneralPackageCard from "./GeneraPackageCard";
import { useChainContext } from "../../../containers/context/context";

const OtcMarketplaceDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const marketplaceId = searchParams.get("id");
  const [marketplaceData, setMarketplaceData] = useState([]);
  const { setReFetchData, reFethData } = useChainContext();

  const [marketplacePackagesData, setMarketplacePackagesData] = useState([]);
  const [MymarketplacePackagesData, setMyMarketplacePackagesData] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [updateModalData, setUpdateModalData] = useState(null);
  const [updatePackagesModalData, setUpdatePackagesModalData] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [packageTrx, setPackageTrx] = useState([]);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [myBalance, setMyBalance] = useState([]);
  const [myOtcPackageTrx, setMyOtcPackageTrx] = useState([]);
  const [refferenceCode, setRefferenceCode] = useState("");
  const [addReferral, setAddReferral] = useState(false);
  const [createPackage, setCreatePackage] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [reFetchPackages, setReFetchPackages] = useState(false);

  const fetchOtcMarketplacePackages = async (val) => {
    setIsLoading(true);
    try {
      const payload = {
        otc_marketPlace_id: marketplaceId,
        type: val || "",
      };
      const response = await getOtcMarketPlacePackageWebApp(payload);

      setMarketplacePackagesData(response.data.packages);
      setUpdatePackagesModalData(response.data.packages);
      setShowUpdateModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowUpdateModal(false);
      console.error("Error fetching store details:", error);
    }
  };
  const fetchMyOtcMarketplacePackages = async (val) => {
    setIsLoading(true);
    try {
      const payload = {
        otc_marketPlace_id: marketplaceId,
        type: "my_package",
      };
      const response = await getOtcMarketPlacePackageWebApp(payload);

      setMyMarketplacePackagesData(response.data.packages);
      setUpdatePackagesModalData(response.data.packages);
      setShowUpdateModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowUpdateModal(false);
      console.error("Error fetching store details:", error);
    }
  };
  const fetchOtcMarketplaceById = async () => {
    setIsLoading(true);
    try {
      const payload = {
        marketPlace_id: marketplaceId,
      };
      const response = await getMarketPlaceDetailsById(payload);
      setMarketplaceData(response.data.data);
      setUpdateModalData(response.data.data[0]);
      setShowUpdateModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowUpdateModal(false);
    }
  };
  const fetchOtcPackagesTrx = async () => {
    setIsLoading(true);
    try {
      const payload = {
        marketplace_id: marketplaceId,
      };
      const response = await getMyOtcPackageTrx(payload);
      setPackageTrx(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching store details:", error);
    }
  };
  const fetchMyBalance = async () => {
    setIsLoading(true);
    try {
      const payload = {
        market_place_id: marketplaceId,
      };
      const response = await getMyFromSymbolBalance(payload);
      setMyBalance(response?.data?.balance[0]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching store details:", error);
    }
  };
  const buyOtcMarketPlacePackageAPI = async (item, id) => {
    const [priceSymbol, secondSymbol] = item?.pool[0]?.pool?.split("-");
    setIsLoading(true);
    try {
      const payload = {
        marketplace_id: marketplaceId,
        package_id: item._id,
      };
      const payloadStripe = {
        marketplace_id: marketplaceId,
        package_id: item._id,
        payment_id: id,
        tax:
          marketplaceData[0]?.buy_fee_type === "percentage"
            ? (item?.price * marketplaceData[0]?.buy_fee) / 100
            : marketplaceData[0]?.buy_fee,
      };

      if (priceSymbol === "Fiat Currency") {
        // Call the Stripe-specific function for Fiat Currency
        const response = await buyOtcMarketPlacPpackageStripe(payloadStripe);
        toast.success(response?.data?.Message);
      } else if (secondSymbol === "BTC") {
        if (marketplaceData[0]?.otc_type == "general") {
          const response = await buyOtcMarketPlacePackage_btc_general(payload);
          toast.success(response?.data?.Message);
        } else {
          const response = await buyOtcMarketPlacePackageBtc(payload);
          toast.success(response?.data?.Message);
        }
      } else {
        if (marketplaceData[0]?.otc_type == "general") {
          const response = await buyOtcMarketPlacePackage_general(payload);
          toast.success(response?.data?.Message);
        } else {
          const response = await buyOtcMarketPlacePackage(payload);
          toast.success(response?.data?.Message);
        }
      }

      setIsLoading(false);
      fetchOtcMarketplacePackages();
      fetchTransection();
      poolData();
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.Message || "An error occurred");
    }
  };

  const handleRequest = async () => {
    try {
      setIsLoading(true);
      const payload = {
        market_place_id: marketplaceId,
        referralCode: refferenceCode,
      };
      const res = await sendAccessRequest(payload);
      fetchOtcMarketplaceById();
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      setIsLoading(false);
    }
  };
  const fetchTransection = async () => {
    const payload = {
      marketplace_id: marketplaceId,
    };
    const res = await getMyOtcPackageTrx(payload);
    setMyOtcPackageTrx(res?.data?.data);
  };
  useEffect(() => {
    fetchOtcMarketplaceById();
    fetchOtcPackagesTrx();
    fetchMyBalance();
    fetchTransection();
  }, [marketplaceId, reFetchPackages]);
  useEffect(() => {
    if (marketplaceData[0]?.otc_type == "general") {
      fetchOtcMarketplacePackages("");
    } else {
      fetchOtcMarketplacePackages();
    }
    fetchMyOtcMarketplacePackages();
  }, [marketplaceId, marketplaceData, reFethData, reFetchPackages]);

  const optionColors = [
    { value: "#f79413", label: "Red" },
    { value: "#939396", label: "Blue" },
  ];

  const poolData = async () => {
    const payload = {
      market_place_id: marketplaceId,
    };
    const res = await getPoolBalance(payload);
    setFilterData(res?.data?.balance);
  };
  useEffect(() => {
    poolData();
  }, [marketplaceId]);
  useEffect(() => {
    const res = filterData?.map((item, index) => {
      const graphVal = parseFloat(item?.quantity);
      const symbol = item.symbol;
      const fill = optionColors[index]?.value || "#ffff";
      return { fill, graphVal, symbol };
    });
    setData(res);
  }, [filterData]);

  const createdAt = new Date(marketplaceData[0]?.createdAt);
  const aprilSeventeenth = new Date("2024-04-17T00:00:00.000Z");
  const isAfterAprilSeventeenth = createdAt > aprilSeventeenth;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 99999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="container py-3">
        <h2 hidden className="text-center mb-4">
          OTC Marketplace Details
        </h2>
        {(marketplaceData &&
          marketplaceData[0]?.access_object?.length !== 0 &&
          marketplaceData[0]?.access_object[0]?.status === "approved") ||
        marketplaceData[0]?.otc_type == "general" ? (
          <div className="row px-md-4 mt-md-2 position-relative ">
            <div className="col-lg-12 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-3">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <h3
                    style={{ textTransform: "capitalize" }}
                    id="h2"
                    data-aos="fade-up"
                  >
                    {marketplaceData[0]?.name}
                  </h3>
                </div>
              </div>
              {isAfterAprilSeventeenth && (
                <div
                  className="position-fixed bottom-0 end-0 p-3"
                  style={{ zIndex: 9999 }}
                >
                  <div className="row w-25">
                    <Link to={`/livechat?otcName=${marketplaceData[0]._id}`}>
                      <i className="ml-1 fa-2xl fa fa-comments" />
                    </Link>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3 mt-4"
                  >
                    <Tab
                      eventKey="home"
                      ClassName="text-white"
                      title=" OTC Marketplace Info"
                    >
                      <div className="row text-center">
                        <InfoItem
                          label="pool ID"
                          value={marketplaceData[0]?._id}
                        />

                        <InfoItem
                          label="Name"
                          value={marketplaceData[0]?.market_place_name}
                        />
                        <InfoItem
                          label="Marketplace Type"
                          value={marketplaceData[0]?.otc_type}
                        />

                        {marketplaceData[0]?.otc_type != "general" && (
                          <InfoItem
                            label="Referral Code"
                            value={marketplaceData[0]?.refference_code || "--"}
                          />
                        )}
                        <StatusComponent
                          title="Buy Status"
                          status={marketplaceData[0]?.buy_status}
                        />
                        {marketplaceData[0]?.otc_type != "general" && (
                          <StatusComponent
                            title="Swapping Status"
                            status={marketplaceData[0]?.swapping_status}
                          />
                        )}
                        <StatusComponent
                          title="Transfer Status"
                          status={marketplaceData[0]?.transfer_status}
                        />

                        {marketplaceData[0]?.otc_type != "general" && (
                          <>
                            <InfoItem
                              label="Sold Symbol"
                              value={
                                marketplaceData[0]?.pool.split("-")[0] || "--"
                              }
                            />
                            <InfoItem
                              label="Buy Symbol"
                              value={
                                marketplaceData[0]?.pool.split("-")[1] || "--"
                              }
                            />
                          </>
                        )}

                        <InfoItem
                          label="Tax Token"
                          value={marketplaceData[0]?.tax_token_symbol}
                        />
                        <FeeComponent
                          title="Buy Fee"
                          fee={marketplaceData[0]?.buy_fee}
                          type={marketplaceData[0]?.buy_fee_type}
                        />
                        {marketplaceData[0]?.otc_type != "general" && (
                          <FeeComponent
                            title="Swap Fee"
                            fee={marketplaceData[0]?.swap_fee}
                            type={marketplaceData[0]?.swap_fee_type}
                          />
                        )}
                        <FeeComponent
                          title="Transfer Fee"
                          fee={marketplaceData[0]?.transfer_fee}
                          type={marketplaceData[0]?.transfer_fee_type}
                        />

                        {marketplaceData[0]?.otc_type != "general" && (
                          <InfoItem
                            label="My Balance"
                            value={`${numeral(myBalance?.quantity).format(
                              "0,0.00000"
                            )} ${myBalance?.symbol}`}
                          />
                        )}
                      </div>
                      <hr
                        className="mt-3"
                        style={{ borderTop: "1px solid white", opacity: "1.1" }}
                      />

                      <div className=" w-100 ">
                        <div className="row row-cols-1 row-cols-lg-3">
                          {marketplacePackagesData.map((item, index) => (
                            <div key={index} className="col">
                              <PackageCard
                                cardData={item}
                                PackageCard={PackageCard}
                                tax={
                                marketplaceData[0]?.buy_fee_type === "percentage"
                                  ? (item?.price * marketplaceData[0]?.buy_fee) / 100
                                  : marketplaceData[0]?.buy_fee}
                                onClick={(idval) =>
                                  buyOtcMarketPlacePackageAPI(item, idval)
                                }
                                isDisbale={marketplaceData[0]?.buy_status}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </Tab>
                    {marketplaceData[0]?.otc_type != "general" && (
                      <Tab
                        eventKey="Profile"
                        ClassName="text-white"
                        title="Pool Info"
                      >
                        <div className="row text-center">
                          <InfoItem
                            label="Owner Id"
                            value={marketplaceData[0]?.owner_details[0]?._id}
                          />

                          <InfoItem
                            label="Owner Name"
                            value={marketplaceData[0]?.owner_details[0]?.name}
                          />

                          <InfoItem
                            label=" Owner Email"
                            value={marketplaceData[0]?.owner_details[0]?.email}
                          />
                        </div>
                        <hr
                          className="mt-3"
                          style={{
                            borderTop: "1px solid white",
                            opacity: "1.1",
                          }}
                        />
                        <div className="">
                          {data.length > 0 ? (
                            <PoolInfoGraph
                              newArray={data}
                              defaultPool={marketplaceData[0]?.pool}
                            />
                          ) : (
                            <h3>No Balance Found</h3>
                          )}
                        </div>
                      </Tab>
                    )}
                    <Tab
                      eventKey="Transections"
                      ClassName="text-white"
                      title="All Transactions"
                    >
                      <div>
                        <AllTransectionTable
                          marketplaceID={marketplaceId}
                          marketplaceData={marketplaceData[0]}
                        />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="transections"
                      ClassName="text-white"
                      title="Balance"
                    >
                      <div>
                        <VirtualBalaceTable
                          marketplaceId={marketplaceId}
                          swapPair={marketplaceData[0]?.swap_pairs}
                          marketplaceData={marketplaceData[0]}
                        />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="holderGraph"
                      ClassName="text-white"
                      title="OTC Holder Graph"
                    >
                      <div className="">
                        <OtcGraphHolder marketplaceId={marketplaceId} />
                      </div>
                    </Tab>
                    {marketplaceData[0]?.otc_type == "general" && (
                      <Tab
                        eventKey="createPackage"
                        ClassName="text-white"
                        title="Create Package"
                      >
                        <div className="d-flex flex-column w-100">
                          {marketplaceData[0]?.otc_type == "general" && (
                            <FormButton
                              type="submit"
                              variant="outline-success"
                              className="col-12 col-md-3 gap-2 text-capitalize"
                              onClick={() => setCreatePackage(true)}
                            >
                              Create Package
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </FormButton>
                          )}

                          <div className="row row-cols-1 row-cols-lg-3">
                            {MymarketplacePackagesData.map((item, index) => (
                              <div key={index} className="col">
                                <GeneralPackageCard
                                  reFetchPackages={reFetchPackages}
                                  otcId={marketplaceData[0]._id}
                                  data={item}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row text-center">
            <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
            {marketplaceData[0]?.access_object.length !== 0 &&
              marketplaceData[0]?.otc_type != "general" && (
                <div className="col-lg-4 col-md-12 col-sm-12 flex-column row  py-1 align-items-center justify-content-center">
                  <p className="py-4">
                    Your request is not approved yet once admin approve you will
                    access this marketplace
                  </p>

                  <div className="d-flex justify-content-center  align-self-center  align-items-center gap-4  col-lg-4 col-md-12 col-sm-12 py-1">
                    <p className="py-1">
                      {" "}
                      <h4 style={{ fontWeight: "600" }}> Status:</h4>
                    </p>
                    <h4
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "400",
                      }}
                      className="currentPrice py-2 "
                    >
                      <Badge
                        bg={
                          marketplaceData[0]?.access_object[0]?.status ===
                          "pending"
                            ? "warning"
                            : marketplaceData[0]?.access_object[0]?.status ===
                              "active"
                            ? "success"
                            : marketplaceData[0]?.access_object[0]?.status ===
                              "inactive"
                            ? "danger"
                            : "primary"
                        }
                      >
                        {marketplaceData[0]?.access_object[0]?.status}{" "}
                      </Badge>
                    </h4>
                  </div>
                </div>
              )}
            {marketplaceData[0]?.access_object.length == 0 &&
              marketplaceData[0]?.otc_type != "general" && (
                <div className="row flex-column  col-lg-4 col-md-12 col-sm-12 py-1 align-items-center gap-2 justify-content-center">
                  <p className="py-4">
                    Please Submit request to access this marketplace
                  </p>
                  <div className="d-flex flex-column gap-4  col-lg-12 col-md-12 col-sm-12 py-1">
                    {addReferral && (
                      <ChatSearchInput
                        type="text"
                        placeholder="Enter Referral Code "
                        value={refferenceCode}
                        onChange={(value) => {
                          setRefferenceCode(value.target.value);
                        }}
                        className={`form-control w-100 `}
                      />
                    )}
                    <div className="d-flex ">
                      <FormButton
                        variant="outline-primary"
                        className={"py-3 "}
                        type="submit"
                        onClick={handleRequest}
                        disabled={!refferenceCode && addReferral}
                      >
                        OTC Marketplace Request
                      </FormButton>
                      <FormButton
                        variant="outline-primary"
                        className={"py-3 "}
                        type="submit"
                        onClick={() => {
                          setAddReferral(!addReferral);
                        }}
                      >
                        {!addReferral ? "Add Referral" : "Hide Referral"}
                      </FormButton>
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
        {createPackage && (
          <CreateOtcGeneralPackage
            show={createPackage}
            hide={() => setCreatePackage(false)}
            otcId={marketplaceId}
            reFetchPackages={() => setReFetchPackages(!reFetchPackages)}
          />
        )}
      </div>
    </>
  );
};

export default OtcMarketplaceDetail;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
